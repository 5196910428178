import { MdContactSupport, MdLocationOn } from "react-icons/md"
import comm from "../src/images/com.jpg"
import comm1 from "../src/images/comm1.jpg"
import comm3 from "../src/images/comm3.jpg"
import comm4 from "../src/images/comm4.jpg"
import per1 from "../src/images/per1.svg"
import per2 from "../src/images/per2.svg"
import per3 from "../src/images/per3.svg"
import per4 from "../src/images/per4.svg"
import per5 from "../src/images/per5.svg"
import per6 from "../src/images/per6.svg"
import per7 from "../src/images/per1.svg"
import per8 from "../src/images/per3.svg"
import { FaBox, FaBarcode, FaSearch, FaPhoneAlt, FaInfoCircle } from "react-icons/fa"
import { IoMail } from "react-icons/io5"
import { FaBuilding, FaPlaneDeparture, FaTruckFast } from "react-icons/fa6"
import { AiFillThunderbolt } from "react-icons/ai"
import { IoIosPricetag } from "react-icons/io"

export const headerData = [
    {
        name: "Home",
        redirect: "/"
    },
    {
        name: "Track Your Product",
        redirect: "/track-order"
    },
    {
        name: "About Us",
        redirect: "/about-us"
    },
    {
        name: "Services",
        redirect: "/services"
    }
]

export const servicesData = [
    {
        icon: <FaTruckFast size={40} />,
        title: "Fast Delivery",
        desc: "Get your packages delivered swiftly with our expedited shipping options.",
    },
    {
        icon: <FaBuilding size={40} />,
        title: "Corporate Solutions",
        desc: "Tailored delivery solutions for businesses, ensuring reliable and timely shipments.",
    },
    {
        icon: <FaPlaneDeparture size={40} />,
        title: "International Shipping",
        desc: "Reach global markets with our secure and fast international shipping services.",
    },
]

export const whyChooseData = [
    {
        icon: <AiFillThunderbolt color="#fff"/>,
        title: "Fast and Reliable Delivery",
        description:
            "Experience speedy and dependable delivery services with real-time tracking. Our efficient logistics network ensures your packages reach their destination promptly and safely.",
    },
    {
        icon: <MdContactSupport color="#fff"/>,
        title: "24/7 Customer Support",
        description:
            "Our dedicated customer support team is available around the clock to assist you with any queries or concerns. We are committed to providing exceptional service and ensuring your satisfaction.",
    },
    {
        icon: <IoIosPricetag color="#fff"/>,
        title: "Affordable Rates",
        description:
            "Enjoy competitive pricing without compromising on quality. We offer cost-effective shipping solutions tailored to your needs, helping you save on your logistics expenses.",
    },
];


export const contactUs = [
    {
        name: "General Inquiries",
        icon: <MdContactSupport color="#fff" size={24} />,
        desc: "Have questions about our services? We're here to help.",
        link: "Contact Us",
        redirect: "/contact-us"
    },
    {
        name: "Track Your Package",
        icon: <FaTruckFast color="#fff" size={24} />,
        desc: "Need help with tracking your package? Get assistance here.",
        link: "Track Now",
        redirect: "/track-order"
    },
    {
        name: "About Our Company",
        icon: <FaInfoCircle color="#fff" size={24} />,
        desc: "Learn more about RM Logistics and our mission.",
        link: "Learn More",
        redirect: "/about-us"
    },
]

export const howToTrackOrder = [
    {
        title: "Enter Tracking Number",
        icon: <FaBarcode size={24} color="#FF7F00" />
    },
    {
        title: "Click Track",
        icon: <FaSearch size={24} color="#FF7F00" />
    },
    {
        title: "View Shipment Status",
        icon: <FaBox size={24} color="#FF7F00" />
    },
]

export const aboutUsData = [
    {
        title: "Our Mission",
        desc: "Delivering your packages swiftly and reliably."
    },
    {
        title: "Our Values",
        desc: "Commitment to excellence and customer satisfaction."
    },
    {
        title: "Our Team",
        desc: " A dedicated team ensuring prompt deliveries."
    },
]
export const contactUsData = [
    {
        title: "Our Address",
        desc: "C/o Bhagvana ram Surja, ram swami rampura, Rampura Ramsara, Hanumangarh, Tibbi, Rajasthan, India, 335526",
        icon: <MdLocationOn color="#fff" />
    },
    {
        title: "Mail",
        desc: "support@speedyset.com",
        icon: <IoMail color="#fff" />
    },
    {
        title: "Contact Us",
        desc: "9065666059",
        icon: <FaPhoneAlt color="#fff" />
    },
]
export const cardsData = [
    {
        mainImgSrc: comm,
        profileImgSrc: per1,
        name: "John",
        handle: "@FastCourier"
    },
    {
        mainImgSrc: comm1,
        profileImgSrc: per2,
        name: "Jane",
        handle: "@SpeedyDeliver"
    },
    {
        mainImgSrc: comm4,
        profileImgSrc: per3,
        name: "Mike",
        handle: "@ReliableCourier"
    },
    {
        mainImgSrc: comm3,
        profileImgSrc: per4,
        name: "Emma",
        handle: "@TrackCourier"
    }
];
export const ourTeam = [
    {
        name: "Logistics Coordinator",
        desc: "Coordinate and oversee the logistics of our delivery operations.",
        time: "Full-time",
        work: "Remote"
    },
    {
        name: "Customer Service Representative",
        desc: "Assist customers with their inquiries and provide top-notch support.",
        time: "Part-time",
        work: "New York,NY"
    },
    {
        name: "Software Engineer",
        desc: "Develop and maintain our package tracking system.",
        time: "Full-time",
        work: "San Francisco, CA"
    },
]

export const userReview = [
    {
        id: 1,
        name: 'Olivia Stanton',
        role: 'Business Owner',
        testimonial: 'Speedy Set has been a game-changer for our logistics operations. Their punctual deliveries and responsive customer support have significantly improved our efficiency.',
        rating: 5,
        imageUrl: per6
    },
    {
        id: 2,
        name: 'John Doe',
        role: 'Entrepreneur',
        testimonial: 'The service from Speedy Set is consistently excellent. The fast turnaround on deliveries and their attention to detail have been crucial for my business.',
        rating: 5,
        imageUrl: per5
    },
    {
        id: 3,
        name: 'Jane Smith',
        role: 'Manager',
        testimonial: 'Speedy Set stands out for their professionalism and quick service. They handle our logistics with care and ensure that our packages are always on time.',
        rating: 4,
        imageUrl: per7
    },
    {
        id: 4,
        name: 'Alice Johnson',
        role: 'Freelancer',
        testimonial: 'I’ve had a great experience with Speedy Set. Their delivery service is reliable, and their team is always ready to help with any queries.',
        rating: 4,
        imageUrl: per8
    },
    {
        id: 5,
        name: 'Bob Brown',
        role: 'Small Business Owner',
        testimonial: 'Speedy Set delivers exactly what they promise—fast, reliable service that caters to our logistics needs perfectly. Highly recommended for any small business.',
        rating: 4,
        imageUrl: per3
    }
]
