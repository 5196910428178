import React from "react";

const Map = () => {
  const mapUrl =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25163.651669233495!2d74.51399401465662!3d29.404162220494367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3916984eb1dccefb%3A0x9bbf826d2e1c88db!2sRampura%2C%20Rampura%20Urf%20Ramsara%2C%20Rajasthan%20335525!5e1!3m2!1sen!2sin!4v1723010305614!5m2!1sen!2sin";

  return (
    <div>
      <iframe
        src={mapUrl}
        allowFullScreen=""
        loading="lazy"
        style={{ height: "500px", width: "100%" }}
        referrerPolicy="no-referrer-when-downgrade"
        title="location"
      />
    </div>
  );
};

export default Map;
