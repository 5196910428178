import React from "react";
import { servicesData } from "../mock";

const OurService = () => {
  return (
    <>
      <div
        className="_block-container_3ik3e_44"
        data-id="Y5_dLwmryzfqgEqRxZExx"
      >
        <section className="py-12 bg-white sm:py-16 lg:py-20">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="TITLE-PRIMARY text-4xl font-semibold text-slate-900 sm:text-4xl xl:text-5xl font-pj ">
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=&amp;target=_blank&amp;text=Our%20Services"
                    className="_editable_5dd2t_44"
                  >
                    Our Services
                  </div>
                </span>
              </h2>
              <p className="DESC mt-4 text-base leading-7 text-slate-600 sm:mt-8 font-pj ">
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=&amp;target=_blank&amp;text=FastTrack%20Delivery%20provides%20a%20range%20of%20services%20to%20meet%20your%20delivery%20needs%20efficiently%20and%20reliably."
                    className="_editable_5dd2t_44"
                  >
                    Speedy Set provides a range of services to meet your
                    delivery needs efficiently and reliably.
                  </div>
                </span>
              </p>
            </div>
            <div className="grid grid-cols-1 mt-10 text-center sm:mt-16 sm:grid-cols-2 sm:gap-x-12 gap-y-12 md:grid-cols-3 md:gap-0 xl:mt-24">
              {servicesData?.map((data, index) => {
                return (
                  <div style={{ opacity: 1, transform: "none" }} key={index} >
                    <div
                      className={`md:p-8 lg:p-14 h-full ${
                        index === 1 || index === 2
                          ? `md:border-l border-black/10`
                          : ""
                      }`}
                    >
                      <span className="mx-auto text-sky-500 text-3xl _icon-wrapper_1v2yh_44 bg-[#FFF7E8] p-16 rounded-full">
                        {data?.icon}
                      </span>
                      <h3 className="TITLE-SECONDARY mt-12 text-xl font-bold text-slate-900 ">
                        <span>
                          <div
                            data-element-type="text"
                            data-link="link=&amp;target=_blank&amp;text=Fast%20Delivery"
                            className="_editable_5dd2t_44"
                          >
                            {data?.name}
                          </div>
                        </span>
                      </h3>
                      <p className="TEXT-CONTENT mt-5 text-base text-slate-600 ">
                        <span>
                          <div
                            data-element-type="text"
                            data-link="link=&amp;target=_blank&amp;text=Get%20your%20packages%20delivered%20swiftly%20with%20our%20expedited%20shipping%20options."
                            className="_editable_5dd2t_44"
                          >
                            {data?.desc}
                          </div>
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OurService;
