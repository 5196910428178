import React from "react";
import { userReview } from "../mock";
import star from "../images/star.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/free-mode";
import { Autoplay, Navigation } from "swiper/modules";

const TestimonialCard = ({ testimonial, keyIndex }) => {
  return (
    <div key={keyIndex}>
      <div className="w-full h-full">
        <div className="rounded-xl h-full flex flex-col gap-8 shadow m-2 p-8 bg-white">
          <div className="flex items-center pb-8 border-b border-black/10 ">
            <div
              className="IMAGE h-14 w-14 rounded-full shadow  object-cover aspect-[1/1] _wrap_9anij_44"
              data-element-type="image"
              style={{
                transform: "none",
                opacity: 1,
                objectFit: "none",
                background: "transparent",
                position: "relative",
              }}
            >
              <img
                className="IMAGE h-14 w-14 rounded-full shadow  object-cover aspect-[1/1] _editable-img_9anij_47"
                alt=""
                src={testimonial?.imageUrl}
              />
            </div>
            <div className="pl-3">
              <button
                data-element-type="button"
                className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500"
              >
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=&amp;target=_blank&amp;text=Olivia%20Stanton"
                    className="_editable_5dd2t_44"
                  >
                    {testimonial?.name}
                  </div>
                </span>
              </button>
              <p className="text-slate-500  /90">
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=&amp;target=_blank&amp;text=Business%20Owner"
                    className="_editable_5dd2t_44"
                  >
                    {testimonial?.role}
                  </div>
                </span>
              </p>
            </div>
          </div>
          <div className=" flex flex-col gap-6">
            <p className="TEXT-CONTENT text-slate-500  /90">
              <span>
                <div
                  data-element-type="text"
                  data-link="link=&amp;target=_blank&amp;text=FastTrack%20Delivery%20has%20transformed%20the%20way%20we%20handle%20logistics.%20Their%20service%20is%20fast%2C%20reliable%2C%20and%20their%20customer%20support%20is%20top-notch."
                  className="_editable_5dd2t_44"
                >
                  {testimonial?.testimonial}
                </div>
              </span>
            </p>
            <ul className="flex items-center gap-1">
              {Array.from(
                { length: testimonial.rating },
                (_, index) => index
              ).map((_, index) => {
                return <img src={star} alt="star" key={index} />;
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const TestimonialList = () => {
  return (
    <div>
      <Swiper
        centeredSlides={true}
        speed={900}
        loop
        effect="slide"
        slidesPerView={3}
        navigation={true}
        autoplay={{
          delay: 800,
        }}
        grabCursor={true}
        breakpoints={{
            1200: { slidesPerView: 3 },
            992: { slidesPerView: 1 },
            768: { slidesPerView: 1 },
            576: { slidesPerView: 1 },
            0: { slidesPerView: 1 },
          }}
        className="mySwiper swiper"
        modules={[Autoplay, Navigation]}
      >
        {userReview?.map?.((item, index) => (
          <SwiperSlide key={index}>
            <TestimonialCard keyIndex={index} testimonial={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TestimonialList;
