import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./style/style1.css";
import "./style/style2.css";
import "./style/style3.css";
import "./style/style4.css";
import "./style/style5.css";
import "./style/style6.css";
import "./style/style7.css";
import "./style/style8.css";
import "./style/style9.css";
import "./style/style10.css";
import "./style/style11.css";
import "./style/style12.css";
import "./style/style13.css";
import "./style/style14.css";
import "./style/style15.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import "tailwindcss/tailwind.css";
import "swiper/swiper-bundle.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
