import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import TestimonialList from "./TestimonialCard";
import { useNavigate } from "react-router-dom";
import { cardsData, whyChooseData } from "../mock";
import hero from "../images/hero.jpg";

const Card = ({ mainImgSrc, profileImgSrc, name, handle }) => {
  return (
    <div className="w-full h-15 text-center border border-black/10">
      <div
        className="IMAGE w-full h-15 object-cover _wrap_9anij_44"
        data-element-type="image"
        style={{
          transform: "none",
          opacity: 1,
          objectFit: "none",
          background: "transparent",
          position: "relative",
        }}
      >
        <img
          className="IMAGE w-full h-15 object-cover _editable-img_9anij_47"
          src={mainImgSrc}
          alt=""
          style={{ backgroundColor: "transparent" }}
        />
      </div>
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="_block-container_3ik3e_44"
        data-id="EvRthI0mVKNNoboogAoIm"
      >
        <section className="w-full px-6 py-8 bg-white  md:px-8 md:py-16">
          <div className="w-full h-full mx-auto max-w-7xl grid grid-cols-1 gap-16 md:grid-cols-2">
            <div
              className=" w-full flex flex-col gap-14 justify-between"
              style={{
                opacity: 1,
                transform: "none",
              }}
            >
              <div className="w-full flex flex-col gap-6">
                <div className="w-full flex items-center gap-6">
                  <span>
                    <div
                      data-element-type="text"
                      data-link="link=&amp;target=_blank&amp;text=Welcome%20to%20FastTrack%20Delivery"
                      className="_editable_5dd2t_44 TITLE-SECONDARY w-fit font-light uppercase text-black"
                    >
                      Welcome to Speedy Set
                    </div>
                  </span>
                  <div
                    className="h-[1px] bg-black/20"
                    style={{ width: "50%" }}
                  ></div>
                </div>
                <h1 className="TITLE-PRIMARY text-5xl font-semibold text-slate-900 md:text-6xl">
                  <span>
                    <div
                      data-element-type="text"
                      data-link="link=&amp;target=_blank&amp;text=Fast%20and%20Reliable%20Courier%20Services"
                      className="_editable_5dd2t_44"
                    >
                      Fast and Reliable Courier Services
                    </div>
                  </span>
                </h1>
                <p className="DESC mt-4 text-slate-600 ">
                  <span>
                    <div
                      data-element-type="text"
                      data-link="link=&amp;target=_blank&amp;text=Experience%20the%20best%20in%20courier%20services%20with%20FastTrack%20Delivery.%20Track%20your%20packages%20in%20real-time%20and%20enjoy%20our%20competitive%20pricing."
                      className="_editable_5dd2t_44"
                    >
                      Your trusted partner in reliable courier services. With
                      Speedy Set, you can track your packages in real-time,
                      ensuring peace of mind every step of the way.
                    </div>
                    <br></br>
                    <div
                      data-element-type="text"
                      data-link="link=&amp;target=_blank&amp;text=Experience%20the%20best%20in%20courier%20services%20with%20FastTrack%20Delivery.%20Track%20your%20packages%20in%20real-time%20and%20enjoy%20our%20competitive%20pricing."
                      className="_editable_5dd2t_44"
                    >
                      Enjoy our fast, efficient delivery services and take
                      advantage of our competitive pricing. Experience the
                      difference with Speedy Set – where your delivery needs are
                      our top priority.
                    </div>
                  </span>
                </p>
              </div>
              <button
                data-element-type="button"
                className="BTN-PRIMARY w-fit h-12 px-6 group font-semibold text-sm text-white uppercase flex gap-2 items-center rounded-lg bg-orange-500 hover:bg-orange-400 hover:transition-all hover:duration-300 md:h-14 md:text-base md:px-8"
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=%2Fabout-us&amp;target=_blank&amp;text=Learn%20More"
                    className="_editable_5dd2t_44 _cursor-pointer_5dd2t_48 "
                  >
                    Learn More
                  </div>
                </span>
                <span>
                  <FaArrowRight />
                </span>
              </button>
            </div>
            <div
              style={{
                opacity: 1,
                transform: "none",
              }}
            >
              <div className="relative w-full h-auto">
                {/*   <div id="hover-content">
                  Get your packages delivered within hours with our express
                  delivery service.
                </div> */}
                <div
                  className="w-full h-full flex items-center justify-center relative transition-all duration-300"
                  style={{
                    transformStyle: "preserve-3d",
                  }}
                >
                  <div
                    className="w-full h-full flex flex-col gap-6 rounded-2xl p-5 shadow-sm backdrop-blur-lg bg-gradient-to-b from-white/20 to-white/30transition-all duration-500 "
                    style={{ transform: "none" }}
                  >
                    <div
                      className="IMAGE w-full h-auto aspect-[2/1.4] md:aspect-[3/2] rounded-lg bg-black/10  object-cover _wrap_9anij_44"
                      data-element-type="image"
                      style={{
                        transform: "none",
                        opacity: 1,
                        objectFit: "none",
                        background: "transparent",
                        position: "relative",
                      }}
                    >
                      <img
                        className="IMAGE w-full h-auto aspect-[2/1.4] md:aspect-[3/2] rounded-lg bg-black/10  object-cover _editable-img_9anij_47"
                        src={hero}
                        alt=""
                        style={{
                          backgroundColor: "transparent",
                        }}
                      />
                    </div>
                    {/*    <div className="w-full h-full px-4 flex items-center justify-between md:px-6">
                      <div className="w-full flex flex-col md:gap-1">
                        <span>
                          <div
                            data-element-type="text"
                            data-link="link=&amp;target=_blank&amp;text=Today%27s%20Recommendation"
                            className="_editable_5dd2t_44 TITLE-SECONDARY text-xs font-light uppercase text-black  md:text-sm"
                          >
                            Today's Recommendation
                          </div>
                        </span>
                        <span>
                          <div
                            data-element-type="text"
                            data-link="link=&amp;target=_blank&amp;text=Express%20Delivery"
                            className="_editable_5dd2t_44 TITLE-PRIMARY mt-2 text-lg font-semibold text-black  md:text-xl"
                          >
                            Speedy Set
                          </div>
                        </span>
                        <span>
                          <div
                            data-element-type="text"
                            data-link="link=&amp;target=_blank&amp;text=Speed%2C%20reliability%2C%20and%20efficiency"
                            className="_editable_5dd2t_44 DESC text-sm text-slate-600  md:text-base"
                          >
                            Speed, reliability, and efficiency
                          </div>
                        </span>
                      </div>
                      <span>
                        <div
                          data-element-type="text"
                          data-link="link=&amp;target=_blank&amp;text=5.0"
                          className="_editable_5dd2t_44 TITLE-PRIMARY text-5xl font-semibold text-black md:text-6xl"
                        >
                          5.0
                        </div>
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        className="_block-container_3ik3e_44"
        data-id="V3LoXcl0QpSG0c7EXeeL0"
      >
        <section className="w-full bg-white  px-6 py-8 md:px-8 md:py-16">
          <div
            style={{
              opacity: 1,
              transform: "none",
            }}
          >
            <div className="w-full max-w-7xl mx-auto">
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=&amp;target=_blank&amp;text=Why%20Choose%20FastTrack%20Delivery%3F"
                    className="_editable_5dd2t_44 TITLE-PRIMARY text-4xl font-bold text-slate-900 md:text-5xl"
                  >
                    Why Choose Speedy Set?
                  </div>
                </span>
              </div>
              <div className="max-w-xl mx-auto md:max-w-none flex flex-col md:flex-row md:items-center space-y-8 md:space-y-0 ">
                <div className="md:w-1/2">
                  <div className="flex space-x-6">
                    <div className="w-1/2 space-y-6">
                      {cardsData.slice(0, 2).map((card, index) => (
                        <Card
                          key={index}
                          mainImgSrc={card.mainImgSrc}
                          profileImgSrc={card.profileImgSrc}
                          name={card.name}
                          handle={card.handle}
                        />
                      ))}
                    </div>
                    <div className="w-1/2 mt-6 space-y-6">
                      {cardsData.slice(2).map((card, index) => (
                        <Card
                          key={index}
                          mainImgSrc={card.mainImgSrc}
                          profileImgSrc={card.profileImgSrc}
                          name={card.name}
                          handle={card.handle}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="md:w-1/2 md:pl-10 lg:pl-20">
                  <div className="w-full flex flex-col items-center gap-6 text-center md:text-left md:items-start">
                    {whyChooseData?.map?.((item, index) => (
                      <div
                        className={`flex gap-x-4 bg-white/5 backdrop-blur-xl p-6 ring-1 ring-inset ring-white/10 ${
                          index === 0 || index === 1
                            ? `border-b border-black/15`
                            : ""
                        }`}
                        key={index}
                      >
                        <div className="text-base leading-7">
                          <h3 className="font-semibold text-black">
                            <span>
                              <div
                                data-element-type="text"
                                data-link="link=&amp;target=_blank&amp;text=Our%20Mission"
                                className="_editable_5dd2t_44"
                              >
                                <div className="inline-flex items-center justify-center w-8 h-8 text-white bg-sky-500 rounded-full shrink-0">
                                  <span>{item?.icon}</span>
                                </div>
                                {"   "}
                                {item?.title}
                              </div>
                            </span>
                          </h3>

                          <p className="mt-2 text-slate-600">
                            {item?.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        className="_block-container_3ik3e_44"
        data-id="7XWQ8l3Vf1BX0ObOdpSxb"
      >
        <div className="">
          <div className=" max-w-7xl mx-auto py-20 px-4">
            <div className="grid grid-cols-1 pb-6 text-center">
              <h3 className="TITLE-PRIMARY text-4xl font-semibold text-slate-900   md:text-5xl">
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=&amp;target=_blank&amp;text=Customer%20Reviews"
                    className="_editable_5dd2t_44"
                  >
                    Customer Reviews
                  </div>
                </span>
              </h3>
              <p className="DESC mt-4 text-center  text-slate-700  /70 lg:text-lg ">
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=&amp;target=_blank&amp;text=Hear%20what%20our%20satisfied%20customers%20have%20to%20say%20about%20FastTrack%20Delivery."
                    className="_editable_5dd2t_44"
                  >
                    Hear what our satisfied customers have to say about Speedy
                    Set.
                  </div>
                </span>
              </p>
            </div>
            <div className="mt-6 gap-6">
              <TestimonialList />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
