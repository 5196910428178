// import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Layout from "./components/Layout";
import TrackOrder from "./components/TrackOrder";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import OurService from "./components/OurService";
import Terms from "./components/Terms";
import Policy from "./components/Policy";

function App() {

  return (
    <>
      <Routes>
        <Route path={``} element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/track-order" element={<TrackOrder />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/services" element={<OurService />} />
          <Route path="/term-condition" element={<Terms />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
