import React from "react";
import { useNavigate } from "react-router-dom";
import { contactUs, contactUsData } from "../mock";
import contactUsImage from "../images/com.jpg";
import Map from "./Map";

const ContactUs = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="_block-container_3ik3e_44"
        data-id="6qsrkem-mgDA_5hphjgdR"
      >
        <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 px-6 lg:px-8">
          <div
            className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center opacity-20 _wrap_9anij_44"
            data-element-type="image"
            style={{
              transform: "none",
              opacity: 1,
              objectFit: "none",
              background: "transparent",
              borderRadius: "inherit",
            }}
          >
            <img
              alt="contact-us"
              className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center opacity-20 _editable-img_9anij_47"
              src={contactUsImage}
              style={{ backgroundColor: "transparent" }}
            />
          </div>
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
              {contactUsData?.map((data, index) => {
                return (
                  <div
                    className="flex gap-x-4 rounded-xl bg-white/5 backdrop-blur-xl p-6 ring-1 ring-inset ring-white/10"
                    key={index}
                  >
                    <div className="text-base leading-7">
                      <h3 className="font-semibold text-white">
                        <span>
                          <div
                            data-element-type="text"
                            data-link="link=&amp;target=_blank&amp;text=Our%20Mission"
                            className="_editable_5dd2t_44"
                          >
                            <div className="inline-flex items-center justify-center w-8 h-8 text-white bg-sky-500 rounded-full shrink-0">
                              <span>{data?.icon}</span>
                            </div>
                            {"   "}
                            {data?.title}
                          </div>
                        </span>
                      </h3>
                      <p className="mt-2 text-gray-300">
                        <span>
                          <div
                            data-element-type="text"
                            data-link="link=&amp;target=_blank&amp;text=Delivering%20your%20packages%20swiftly%20and%20reliably."
                            className="_editable_5dd2t_44"
                          >
                            {data?.desc}
                          </div>
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <section className="py-8 bg-white sm:py-16 lg:py-16">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <Map />
          </div>
        </section>
        <div className="isolate bg-white px-6 py-8 sm:py-12 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="TITLE-PRIMARY text-3xl font-bold tracking-tight text-slate-800 sm:text-4xl  /80">
              <span>
                <div
                  data-element-type="text"
                  data-link="link=&amp;target=_blank&amp;text=Contact%20Us"
                  className="_editable_5dd2t_44"
                >
                  Contact Us
                </div>
              </span>
            </h2>
            <p className="DESC mt-2 text-lg leading-8 text-slate-700  /60">
              <span>
                <div
                  data-element-type="text"
                  data-link="link=&amp;target=_blank&amp;text=Reach%20out%20to%20FastTrack%20Delivery%20for%20any%20inquiries%20or%20support."
                  className="_editable_5dd2t_44"
                >
                  Reach out to RM Logistics for any inquiries or support.
                </div>
              </span>
            </p>
          </div>
          <div className="mx-auto mt-20 max-w-lg space-y-16">
            {contactUs?.slice?.(1, contactUs?.length)?.map((data, index) => {
              return (
                <div key={index}>
                  <div className="flex gap-x-6">
                    <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-sky-500">
                      <span>{data?.icon}</span>
                    </div>
                    <div>
                      <h3 className="TITLE-SECONDARY text-base font-semibold leading-7 text-slate-900  /80">
                        <span>
                          <div
                            data-element-type="text"
                            data-link="link=&amp;target=_blank&amp;text=General%20Inquiries"
                            className="_editable_5dd2t_44"
                          >
                            {data?.name}
                          </div>
                        </span>
                      </h3>
                      <p className="DESC mt-2 leading-7 text-slate-600  /60">
                        <span>
                          <div
                            data-element-type="text"
                            data-link="link=&amp;target=_blank&amp;text=Have%20questions%20about%20our%20services%3F%20We%27re%20here%20to%20help."
                            className="_editable_5dd2t_44"
                          >
                            {data?.desc}
                          </div>
                        </span>
                      </p>
                      <p className="mt-4">
                        <button
                          data-element-type="button"
                          className="TEXT-LINK text-sm flex items-center gap-1 group font-semibold leading-6 text-sky-400"
                          onClick={() => {
                            navigate(data?.redirect);
                          }}
                        >
                          <span>
                            <div
                              data-element-type="text"
                              data-link="link=%2Fhome&amp;target=_blank&amp;text=Contact%20Us"
                              className="_editable_5dd2t_44 _cursor-pointer_5dd2t_48"
                            >
                              {data?.link}
                            </div>
                          </span>
                          <span>{data?.icon}</span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
