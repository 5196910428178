import React, { useState } from "react";
import logo from "../images/logo.svg";
import logoIcon from "../images/favicon.svg";
import { FaArrowRight } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { headerData } from "../mock";
import { RiMenu3Line } from "react-icons/ri";
import { IoClose } from "react-icons/io5";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [openMenu, setOpenMenu] = useState(false);
  return (
    <>
      <div
        className="_block-container_3ik3e_44"
        data-id="tFLr8oHdO3tKIxE4D_es1"
      >
        <section className="sticky top-0 z-50 w-full h-16 bg-white/80 backdrop-blur-xl md:px-8 header">
          <div className="w-full mx-auto max-w-7xl h-full hidden gap-5 md:grid md:grid-cols-[0.4fr_1fr_0.4fr] md:items-center ">
            <div className="w-full h-full flex items-center justify-start">
              <div
                className="w-auto h-10 object-cover _wrap_9anij_44"
                data-element-type="image"
                style={{
                  transform: "none",
                  opacity: 1,
                  objectFit: "none",
                  background: "transparent",
                  position: "relative",
                  borderRadius: "inherit",
                }}
                onClick={()=>{
                  navigate("/")
                }}
              >
                <img
                  alt="logo"
                  className="w-auto h-10 object-cover _editable-img_9anij_47 lg:block md:hidden"
                  src={logo}
                  style={{
                    backgroundColor: "transparent",
                    
                  }}
                />
                <img
                  alt="logo"
                  className="w-auto h-10 object-cover _editable-img_9anij_47 lg:hidden"
                  src={logoIcon}
                  style={{
                    backgroundColor: "transparent",
                    
                  }}
                />
              </div>
            </div>
            <div className="w-full h-full flex items-center justify-center">
              <nav className="w-full h-full flex items-center justify-center">
                {headerData?.map((data, index) => {
                  return (
                    <button
                      key={index}
                      data-element-type="button"
                      className={`px-6 whitespace-nowrap cursor-pointer hover:text-[#FF7F00] hover:transition-all duration-100  ${
                        pathname === data?.redirect
                          ? "text-[#FF7F00]"
                          : "text-black"
                      }`}
                      onClick={() => {
                        navigate(data?.redirect);
                      }}
                    >
                      <span>
                        <div
                          data-element-type="invalid-text"
                          data-link="link=home&amp;target=_blank&amp;text=Home"
                          className="_editable_5dd2t_44 _cursor-pointer_5dd2t_48"
                        >
                          {data?.name}
                        </div>
                      </span>
                    </button>
                  );
                })}
              </nav>
            </div>
            <div className="w-full h-full flex items-center justify-end">
              <button
                data-element-type="button"
                className="w-fit h-10 px-5 group text-sm whitespace-nowrap font-semibold text-white flex gap-2 items-center rounded-full bg-blue-500 hover:bg-blue-600 hover:transition-all hover:duration-300"
                onClick={() => {
                  navigate("contact-us");
                }}
              >
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=&amp;target=_blank&amp;text=Contact%20us"
                    className="_editable_5dd2t_44"
                  >
                    Contact Us
                  </div>
                </span>
                <span>
                  <FaArrowRight />
                </span>
              </button>
            </div>
          </div>
          <div className="relative w-full h-full flex items-center md:hidden">
            <div
              className="w-auto h-10 object-cover _wrap_9anij_44"
              data-element-type="image"
              style={{
                transform: "none",
                opacity: 1,
                objectFit: "none",
                background: "transparent",
                position: "relative",
                borderRadius: "inherit",
              }}
            >
              <img
                alt="logo"
                className="w-auto h-10 object-cover _editable-img_9anij_47"
                src={logo}
                style={{
                  backgroundColor: "transparent",
                }}
              />
            </div>
            <button
              className="absolute right-6"
              tabIndex="0"
              style={{ transform: "none" }}
              onClick={() => {
                setOpenMenu(!openMenu);
              }}
            >
              {openMenu ? <IoClose size={24} /> : <RiMenu3Line size={24} />}
            </button>
            {openMenu ? (
              <div className="absolute z-50 top-16 p-4 w-screen flex flex-col gap-3 bg-white rounded-b-xl shadow-xl">
                <nav
                  className="w-full py-3 flex flex-col"
                  style={{ opacity: 1, transform: "none" }}
                >
                  {headerData?.map((data) => {
                    return (
                      <button
                        data-element-type="button"
                        className={`w-full px-4 py-3 text-black text-left whitespace-nowrap cursor-pointer hover:text-black/60 hover:transition-all duration-100 `}
                        onClick={() => {
                          navigate(data?.redirect);
                          setOpenMenu(false);
                        }}
                      >
                        <span>
                          <div
                            data-element-type="invalid-text"
                            data-link="link=home&amp;target=_blank&amp;text=Home"
                            className={`_editable_5dd2t_44 _cursor-pointer_5dd2t_48 ${
                              pathname === data?.redirect
                                ? "text-[#FF7F00]"
                                : "text-black"
                            }`}
                          >
                            {data?.name}
                          </div>
                        </span>
                      </button>
                    );
                  })}
                </nav>
                <button
                  data-element-type="button"
                  className="w-fit h-10 px-5 group text-sm whitespace-nowrap font-semibold text-white flex gap-2 items-center rounded-full bg-blue-500 hover:bg-blue-600 hover:transition-all hover:duration-300"
                  onClick={() => {
                    navigate("/contact-us");
                    setOpenMenu(false);
                  }}
                >
                  <span>
                    <div
                      data-element-type="text"
                      data-link="link=&amp;target=_blank&amp;text=Contact%20us"
                      className="_editable_5dd2t_44"
                    >
                      Contact Us
                    </div>
                  </span>
                  <span>
                    <FaArrowRight />
                  </span>
                </button>
              </div>
            ) : null}
          </div>
        </section>
      </div>
    </>
  );
};

export default Header;
