import React from "react";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="_block-container_3ik3e_44"
        data-id="bNVGzn3VQS9UoA9LgiegB"
      >
        <section className="py-10 bg-black">
          <div>
            <div className="mx-auto px-4 max-w-7xl py-10 flex flex-col items-center gap-12">
              <span>
                <div
                  data-element-type="text"
                  data-link="link=&amp;target=_blank&amp;text=%C2%A9%202024%20FastTrack%20Delivery"
                  className="_editable_5dd2t_44 DESC ml-2  text-sm text-white/60"
                >
                  © 2024 Speedy Set
                </div>
              </span>
              <div className="flex flex-col gap-8 items-center md:flex-row">
                <ul className="flex flex-col items-center gap-8 sm:flex-row">
                  {[
                    { text: "About Us", link: "/about-us" },
                    { text: "Our Services", link: "/services" },
                    { text: "Privacy Policy", link: "/policy" },
                    { text: "Terms", link: "/term-condition" },
                    { text: "Contact Us", link: "/contact-us" },
                  ].map((item, index) => (
                    <li key={index}>
                      <button
                        className="TEXT-LINK text-sm text-white hover:text-sky-500 focus:text-sky-500"
                        onClick={() => {
                          navigate(item?.link);
                        }}
                      >
                        <div
                          data-element-type="text"
                          data-link={`link=${item.link}&target=_blank&text=${item.text}`}
                          className="_editable_5dd2t_44 _cursor-pointer_5dd2t_48"
                        >
                          {item.text}
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>

                <div className="w-full h-px bg-white/20 md:w-px md:h-6"></div>
                <ul className="flex items-center gap-8">
                  <li href="#">
                    <Link
                      data-element-type="button"
                      className="TEXT-LINK text-white"
                      to={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        "C/o Bhagvana ram Surja, ram swami rampura, Rampura Ramsara, Hanumangarh, Tibbi, Rajasthan, India, 335526"
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <FaLocationDot />
                      </span>
                    </Link>
                  </li>
                  <li href="#">
                    <Link
                      data-element-type="button"
                      className="TEXT-LINK text-white"
                      to="mailto:support@speedyset.com"
                    >
                      <span>
                        <IoMail />
                      </span>
                    </Link>
                  </li>
                  <li href="#">
                    <button
                      data-element-type="button"
                      className="TEXT-LINK text-white"
                    >
                      <span>
                        <FaPhone />
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;
