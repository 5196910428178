import React, { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { howToTrackOrder } from "../mock";
import Loader from "./Loader";
import axios from "axios";

const TrackOrder = () => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [error, setShowError] = useState("");
  const [detail, setDetail] = useState({});
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setShowError("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!inputValue?.length) {
        setShowError("Please add tracking id");
        return;
      }
      setLoading(true);
      const response = await axios.post(
        `https://api.zippyglide.com/api/orders/get-by-id`,
        {
          id: inputValue,
          site_url: "speedyset.com",
        }
      );
      if (response?.data?.status) {
        setFormData(response?.data);
        setInputValue("");
        setShowMessage(false);
      } else {
        setShowMessage(true);
        setInputValue("");
       /*  setTimeout(() => {
          setShowMessage(false);
          setDetail({});
        }, 3000); */
      }
      setLoading(false);
    } catch (error) {
      console.error("Error submitting the form:", error);
      setInputValue("");
      setShowMessage(true);
     /*  setTimeout(() => {
        setShowMessage(false);
        setDetail({});
      }, 3000); */
    } finally {
      setLoading(false);
    }
  };
  const setFormData = (data) => {
    const details = [
      { label: "Sender Name", value: data?.data?.company_name },
      { label: "Tracking ID", value: data?.data?.tracking_id },
      {
        label: "Delivery Date",
        value: new Date(data?.data?.deli_date).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
      },
      {
        label: "Receive Date",
        value: new Date(data?.data?.recive_date).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
      },
      { label: "Receiver Name", value: data?.data?.cust_name },
      { label: "To Address", value: data?.data?.to_address },
      { label: "Sender Address", value: data?.data?.f_address },
    ];
    setDetail(details);
  };

  return (
    <>
      <div
        className="_block-container_3ik3e_44"
        data-id="uxk49BZJ30KK5_JnoyLBM"
      >
        <div className="bg-white py-24 sm:py-32 relative w-full h-full mx-auto max-w-7xl ">
          <div className="grid grid-cols-1 gap-16 md:grid-cols-2">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 relative">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <p className="DESC text-base font-semibold leading-7 text-sky-600">
                  <span>
                    <div
                      data-element-type="text"
                      data-link="link=&amp;target=_blank&amp;text=Track%20Your%20Shipment"
                      className="_editable_5dd2t_44"
                    >
                      Track Your Shipment
                    </div>
                  </span>
                </p>
                <h4 className="TITLE-PRIMARY mt-2 text-4xl font-bold tracking-tight text-slate-900 sm:text-6xl">
                  <span>
                    <div
                      data-element-type="text"
                      data-link="link=&amp;target=_blank&amp;text=FastTrack%20Delivery"
                      className="_editable_5dd2t_44"
                    >
                      Speedy Set
                    </div>
                  </span>
                </h4>
                <p className="DESC mt-6 text-lg leading-8 text-slate-700">
                  <span>
                    <div
                      data-element-type="text"
                      data-link="link=&amp;target=_blank&amp;text=Easily%20track%20your%20package%20with%20our%20reliable%20and%20speedy%20courier%20services."
                      className="_editable_5dd2t_44"
                    >
                      Easily track your package with our reliable and speedy
                      courier services.
                    </div>
                  </span>
                </p>
              </div>
            </div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8 relative w-full">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <div
                  className="w-full h-full flex flex-col gap-6 rounded-2xl p-5 shadow-sm shadow-black/5 border border-black/10 backdrop-blur-lg bg-gradient-to-b from-white/20 to-white/30transition-all duration-500 "
                  style={{ transform: "none" }}
                >
                  <form className="mybtn">
                    <div className="w-full">
                      <input
                        type="text"
                        placeholder="Track Shipment..."
                        className="form-control"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                      {error ? (
                        <span className="text-[red] text-sm">{error}</span>
                      ) : null}
                    </div>

                    <button
                      type="submit"
                      className="primarybtn"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      {loading ? (
                        <Loader loaderClassName="w-5" />
                      ) : (
                        <IoIosSearch size={26} />
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white py-16 sm:py-8 ">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 relative w-full">
              <div className="w-full">
                {showMessage ? (
                  <div className="message pt-1">Order Details not found!</div>
                ) : detail?.length ? (
                  <div className="bg-slate-50 p-6 rounded-lg order_main w-full">
                    <h3 className="text-lg font-semibold text-slate-900">
                      Shipment Tracking Details
                    </h3>
                    <ul className="mt-4 space-y-2 grid lg:grid-cols-2 md:grid-cols-1">
                      {detail?.map?.((detail, index) => {
                        return (
                          <li key={index}>
                            <span className="font-semibold">
                              {detail.label} :{" "}
                            </span>{" "}
                            <span>
                              <div
                                data-element-type="text"
                                data-link="link=&amp;target=_blank&amp;text=123456789"
                                className="_editable_5dd2t_44"
                              >
                                {detail.value}
                              </div>
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {!detail?.length ? (
            <div className="mx-auto max-w-7xl px-6 lg:px-8 relative w-full">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <div
                  className="h-[1px] bg-black/20"
                  style={{ width: "100%" }}
                ></div>
              </div>
            </div>
          ) : null}
          <div
            className="_block-container_3ik3e_44"
            data-id="O4XjuHIiHdNy8OBMQpmlN"
          >
            <section className="bg-white px-6 py-16 md:px-8 md:py-16">
              <div style={{ opacity: 1, transform: "none" }}>
                <div className="w-full max-w-7xl mx-auto flex flex-col gap-12">
                  <span>
                    <div
                      data-element-type="text"
                      data-link="link=&amp;target=_blank&amp;text=Tracking%20FAQs"
                      className="_editable_5dd2t_44 TITLE-PRIMARY text-slate-900 font-bold text-4xl"
                    >
                      Tracking FAQs
                    </div>
                  </span>
                  <div className="md:flex md:space-x-12 space-y-8 md:space-y-0">
                    <div className="w-full md:w-1/2 space-y-8">
                      {[
                        {
                          question: "Where can I find my tracking number?",
                          answer:
                            "You can locate your tracking number in the shipping confirmation email we sent you after your order was dispatched. It is also available in your account under 'Order History'.",
                        },
                        {
                          question:
                            "What should I do if my package is delayed?",
                          answer:
                            "If you experience a delay with your package, please reach out to our customer support team. They will provide you with an update and assist in resolving any issues.",
                        },
                        {
                          question:
                            "Is it possible to update the delivery address after the package has been shipped?",
                          answer:
                            "Once a package is in transit, changes to the delivery address can be challenging. However, contact our customer support as soon as possible, and we will do our best to accommodate your request.",
                        },
                      ].map((item, index) => (
                        <div key={index} className="space-y-2">
                          <div
                            data-element-type="text"
                            data-link={`link=&target=_blank&text=${encodeURIComponent(
                              item.question
                            )}`}
                            className="_editable_5dd2t_44 TITLE-SECONDARY text-slate-900 text-xl font-medium"
                          >
                            {item.question}
                          </div>
                          <div
                            data-element-type="text"
                            data-link={`link=&target=_blank&text=${encodeURIComponent(
                              item.answer
                            )}`}
                            className="_editable_5dd2t_44 DESC text-slate-600"
                          >
                            {item.answer}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="w-full md:w-1/2 space-y-8">
                      {[
                        {
                          question:
                            "How often is the tracking information updated?",
                          answer:
                            "Tracking information is typically updated every few hours as your package progresses through our network. For real-time updates, check your tracking details on our website or app.",
                        },
                        {
                          question:
                            "What do the different tracking statuses mean?",
                          answer:
                            "Tracking statuses provide insights into your package's journey. For example, 'In Transit' means your package is on its way to the delivery address, while 'Out for Delivery' indicates it is with the courier and will be delivered soon.",
                        },
                        {
                          question: "Can I track multiple packages at once?",
                          answer:
                            "Yes, you can track multiple packages by entering each tracking number individually on our tracking page. Alternatively, you can use our bulk tracking feature if available in your account.",
                        },
                      ].map((item, index) => (
                        <div key={index} className="space-y-2">
                          <div
                            data-element-type="text"
                            data-link={`link=&target=_blank&text=${encodeURIComponent(
                              item.question
                            )}`}
                            className="_editable_5dd2t_44 TITLE-SECONDARY text-slate-900 text-xl font-medium"
                          >
                            {item.question}
                          </div>
                          <div
                            data-element-type="text"
                            data-link={`link=&target=_blank&text=${encodeURIComponent(
                              item.answer
                            )}`}
                            className="_editable_5dd2t_44 DESC text-slate-600"
                          >
                            {item.answer}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div
            className="_block-container_3ik3e_44"
            data-id="4RH0VMKtu0ZwBmIApKjgm"
          >
            <section className="py-10 bg-slate-50">
              <div className="px-4 mx-auto max-w-7xl py-10 flex flex-col gap-20">
                <div style={{ opacity: 1, transform: "none" }}>
                  <div className="max-w-lg mx-auto text-center flex flex-col gap-6">
                    <h1 className="text-4xl font-semibold text-slate-900 ">
                      <span>
                        <div
                          data-element-type="text"
                          data-link="link=&amp;target=_blank&amp;text=How%20to%20Track%20Your%20Product"
                          className="_editable_5dd2t_44"
                        >
                          How to Track Your Product
                        </div>
                      </span>
                    </h1>
                    <p className="text-base font-normal text-slate-600 ">
                      <span>
                        <div
                          data-element-type="text"
                          data-link="link=&amp;target=_blank&amp;text=Follow%20these%20simple%20steps%20to%20track%20your%20shipment."
                          className="_editable_5dd2t_44"
                        >
                          Follow these simple steps to track your shipment.
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
                  {howToTrackOrder?.map((order, index) => {
                    return (
                      <div
                        style={{ opacity: 1, transform: "none" }}
                        key={index}
                      >
                        <div className="h-full bg-white border border-black/10   rounded-lg">
                          <div className="h-full p-6 flex flex-col gap-16 justify-between">
                            <span>{order?.icon}</span>
                            <p className="text-base font-normal text-slate-900 ">
                              <span>
                                <div
                                  data-element-type="text"
                                  data-link="link=&amp;target=_blank&amp;text=Enter%20Tracking%20Number"
                                  className="_editable_5dd2t_44"
                                >
                                  {order?.title}
                                </div>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackOrder;
